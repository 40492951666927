import { Link } from 'react-router-dom';
import './Footer.css';
// check this file for branch/git issues; bad imports should be gone

export default function Footer() {

    return (
        <div className="Footer">
            <hr />
            <div className="footerContents">
                <div className="footerFlex">
                    {/* <Link to="/">Home</Link> */}
                    <Link to="/about">About</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="/upgrade">Plans</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms">Terms of Use</Link>
                </div>
                <div className="footerFlex">
                    <span className="copyright">© 2024 PageThinker.&nbsp;All Rights Reserved</span>
                </div>
                <div className="footerSettings">
                    {/* <button onClick={() => theme.setDark(!theme.dark)}>
                        {theme.dark ? <SunIcon/> : <MoonIcon/>}
                    </button> */}
                </div>
            </div>
        </div>
    )
}